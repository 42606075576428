var AppiPayments = (function() {
    "use strict";

    // Set defaults here
    var defaults = {
		createNonMemberBtn: "create_non_member_btn",
		fileImportClass: "custom-file-import",
    };

    // Public properties
	var pub = {};

    // Private properties
    var payablesLocalCache = {},
        total = 0,
        pageTotal = 0,
        form_data = null,
        dtTable = null,          // The datatables table.
        dtTableId = null,        // The html id of the payments table.
        pef_validator = null,    // The validator beeing used in the payments' create/edit form.
        ef_validator = null,     // The validator for the email form.
        mf_validator = null;     // The validator for the member form.

    /**
     * Checks if payment is still active, based on its due date.
     * This function is responsible for adding a DELTA to the date according
     * to the society business rules.
     *
     * @param paymentDueDate Payment due date.
     */
    function paymentIsActive(paymentDueDate) {
        var DELTA = parseInt(APPIconfig['payables_date_extension']);

        if (isNaN(DELTA)) {
            DELTA = 180;
        }
        paymentDueDate.setDate(paymentDueDate.getDate() + DELTA)
        if (paymentDueDate > Date.now()) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * This is a "hack" to remove html entities form a select option text.
     *
     * @param {*} encodedString
     * @returns
     */
    function decodeHtmlEntities(encodedString) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value;
    }

	/**
	 * Callback of an "upload" button to import a Payment form
	 * sent by emnail. It is supposed to be called from a Bootstrap modal.
	 *
	 * The files are expected to be in EML. This format was choosen because
	 * EML files are text files that can be opened using most e-mail clients,
	 * such as Microsoft Outlook, Mozilla Thunderbird, Apple Mail, or
	 * IncrediMail.
	 *
	 * @param {event} loadEvt - The event.
	 */
    pub.ImportApplicationForm = function(loadEvt) {
        var file = $(this)[0].files[0];

        // Eml files only.
        if (!file.name.match('^.+\.eml$')) {
            bootbox.dialog({
                    message: "Só são admitidos ficheiros EML.",
                    title:   "Ficheiro Inválido",
                    icon:    "fa-exclamation text-danger",
                    buttons: { OK: { } }
            });
            return;
        }

        var reader = new FileReader();

        /**
         * The callback associated to the reader.
         *
         * @param  {event}
         * @todo - Lots of margin for improvement here.
         */
        reader.onload = function(e) {
            var text = e.target.result;
            var form = loadEvt.target.closest('form');
            var modal = loadEvt.target.closest('.modal');

            form_data = AppiUtils.parsePaymentFormEML(text);
            var db_data;

            // Update title to reflect import and show file name.
            AppiUtils.SetModalTitle(modal, 'Importar Pagamento - ' + file.name);

            // Erase all input fields and validation errors on the form
            $(form)[0].reset();   // This was making bootstrap-select misbehave. TODO
            $('.selectpicker').val('').selectpicker('deselectAll').trigger('change');
            //$('.selectpicker').selectpicker('refresh');
            pef_validator.resetForm();

            $('#member_create_non_member_btn').prop('disabled', true);
            $('#payer_create_non_member_btn').prop('disabled', true);

            // Add raw text to import button title so that it shows "on hover".
            $('.custom-file-import').attr('title', form_data['raw_text']);

            // //////////////////////////////////////////////////////////////////////
            // Common fields - This must be done before the following checks

            // Update select picker with payment method
            $('#payment_method_id').val( $('#payment_method_id option').filter(function () { return $(this).html().toLowerCase() == form_data['payment_method'].toLowerCase(); }).val() );
            $('#payment_method_id').selectpicker('refresh');
            $('#payment_proof').val(form_data['payment_proof']);
            // Assume email date as payment date
            $('#payment_date').datepicker('setDate', form_data['email_date']);
            $('#member_name').val(form_data['member_name']);
            $('#member_number').val(form_data['member_number']);

            // Common fields - This must be done before the following checks
            // //////////////////////////////////////////////////////////////////////

            // Check if this is a payment form
            if (form_data['type'] == 'FEE') {

                // Update select picker with the correct fee
                var found = false;
                $.each(payablesLocalCache, function( id, fee ) {
                    if (AppiUtils.toDate(fee.due_date) && AppiUtils.toDate(fee.due_date).getFullYear().toString() == form_data['fee_year']) {
                        $("#payable_id").val(id);
                        $('#payment_amount').val(fee.amount_due);
                        $('#payable_id').selectpicker('refresh');
                        found = true;
                    }
                });
                if (!found) {
                    bootbox.dialog({
                            message: __("Essa quota parece já não estar a pagamento."),
                            title:   __("Pagamentos"),
                            icon:    "fa-exclamation text-danger",
                            buttons: { OK: { } }
                    });
                    return;
                }

                // Try to get information about the Payer
                found = false;

                // If we have a number, let's start by trying to use it.
                if (!found && form_data['member_number']) {
                    $.ajax({
                        url: '/members',
                        type: "GET",
                        async: false,
                        data: $.param( { number: form_data['member_number'] } )
                    }).done(function(data) {
                        if (data.length == 1) {
                            if (!$('#member_name').val())
                                $('#member_name').val(data[0]['full_name']);
                            $('#member_number').val(data[0]['number']);
                            AppiUtils.addHiddenElementToForm('edit_payment_form', 'member_id', data[0]['id']);
                            AppiUtils.addHiddenElementToForm('edit_payment_form', 'payer_id', data[0]['id']);
                            found = true;
                        }
                    }).fail(function() {
                        bootbox.dialog({
                                message: __("Erro a obter associado."),
                                title:   __("Erro no acesso à BD"),
                                icon:    "fa-exclamation text-danger",
                                buttons: { OK: { } }
                        });
                    });
                }

                if (!found && form_data['member_name']) {
                    $.ajax({
                        url: '/members',
                        type: "GET",
                        async: false,
                        data: $.param( { full_name: form_data['member_name'] } )
                    }).done(function(data) {
                        if (data.length == 1) {
                            $('#member_name').val(data[0]['full_name']);
                            if (!$('#member_number').val())
                                $('#member_number').val(data[0]['number']);
                            AppiUtils.addHiddenElementToForm('edit_payment_form', 'member_id', data[0]['id']);
                            AppiUtils.addHiddenElementToForm('edit_payment_form', 'payer_id', data[0]['id']);
                            found = true;
                        }
                    }).fail(function() {
                        bootbox.dialog({
                                message: __("Erro a obter associado."),
                                title:   __("Erro no acesso à BD"),
                                icon:    "fa-exclamation text-danger",
                                buttons: { OK: { } }
                        });
                    });
                }

                if (!found) {
                    bootbox.dialog({
                            message: __("Os dados do pagador não foram encontrados na BD."),
                            title:   __("Pagamento"),
                            icon:    "fa-exclamation text-danger",
                            buttons: { OK: { } }
                    });
                }
            }
            else if (form_data['type'] == 'CONFERENCE' || form_data['type'] == 'APPIFORMA') {

                // We don't know what this is, so show email subject
                AppiUtils.SetModalTitle(modal, 'Importar Pagamento - ' + form_data['email_subject']);

                $('#payment_amount').val(form_data['payment_amount']);

                // Try to get information about the Payer
                found = false;

                // If we have a number, let's start by trying to use it.
                if (!found && form_data['member_number']) {
                    $.ajax({
                        url: '/members',
                        type: "GET",
                        async: false,
                        data: $.param( { number: form_data['member_number'] } )
                    }).done(function(data) {
                        if (data.length == 1) {
                            if (!$('#member_name').val())
                                $('#member_name').val(data[0]['full_name']);
                            $('#member_number').val(data[0]['number']);
                            AppiUtils.addHiddenElementToForm('edit_payment_form', 'member_id', data[0]['id']);
                            AppiUtils.addHiddenElementToForm('edit_payment_form', 'payer_id', data[0]['id']);
                            found = true;
                        }
                    }).fail(function() {
                        bootbox.dialog({
                                message: __("Erro a obter dados do associado."),
                                title:   __("Erro no acesso à BD"),
                                icon:    "fa-exclamation text-danger",
                                buttons: { OK: { } }
                        });
                    });
                }

                if (!found && form_data['member_name']) {
                    $.ajax({
                        url: '/members',
                        type: "GET",
                        async: false,
                        data: $.param( { full_name: form_data['member_name'] } )
                    }).done(function(data) {
                        if (data.length == 1) {
                            $('#member_name').val(data[0]['full_name']);
                            if (!$('#member_number').val())
                                $('#member_number').val(data[0]['number']);
                            AppiUtils.addHiddenElementToForm('edit_payment_form', 'member_id', data[0]['id']);
                            AppiUtils.addHiddenElementToForm('edit_payment_form', 'payer_id', data[0]['id']);
                            found = true;
                        }
                    }).fail(function() {
                        bootbox.dialog({
                                message: __("Erro a obter dados do associado."),
                                title:   __("Erro no acesso à BD"),
                                icon:    "fa-exclamation text-danger",
                                buttons: { OK: { } }
                        });
                    });
                }

            }
            else {
                bootbox.dialog({
                        message: "Esta mensagem não contem um aviso de pagamento.",
                        title:   "Pagamentos",
                        icon:    "fa-exclamation text-danger",
                        buttons: { OK: { } }
                });
                return;
            }
        };
        reader.readAsText(file);
    }

    pub.EditPaymentFormSubmitHandler = function(event) {
        var modal = $(this).closest('.modal');
        var action = $('#edit_payment_form').closest('.modal').data('action');
        var found = false;

        modal.find('.spinner').show();

        AppiUtils.addHiddenElementToForm('edit_payment_form', 'member_id', 0);
        AppiUtils.addHiddenElementToForm('edit_payment_form', 'payer_id', 0);

        // Fetch member data to see if exists and it is correct
        var xhr_member = $.ajax({
                             url: '/members',
                             type: "GET",
                             async: false,
                             data: $.param( $('#member_number').val() ? { number: $('#member_number').val() } :
                                                                        { full_name: $('#member_name').val() } )
                         }).done(function(data) {
                             if (data.length == 1 &&
                                 (AppiUtils.isSameName($('#member_name').val(), data[0]['full_name']) ||
                                     AppiUtils.isSameNumber($('#member_number').val(), data[0]['number'])) ) {
                                 if (!$('#member_name').val())
                                     $('#member_name').val(data[0]['full_name']);
                                 if (!$('#member_number').val())
                                     $('#member_number').val(data[0]['number']);

                                 AppiUtils.addHiddenElementToForm('edit_payment_form', 'member_id', data[0]['id']);
                                 found = true;
                             }
                         }).fail(function() {
                             bootbox.dialog({
                                    message: __("Erro a obter dados do associado."),
                                    title:   __("Erro no acesso à BD"),
                                    icon:    "fa-exclamation text-danger",
                                    buttons: { OK: { } }
                             });
                         });

        var xhr_payer = undefined;
        if ($('#same_for_receipt').is(':checked')) {
            $('#payer_number').val($('#member_number').val());
            $('#payer_name').val($('#member_name').val());
            AppiUtils.addHiddenElementToForm('edit_payment_form', 'payer_id', $('#member_id').val());
        }
        else {

            xhr_payer = $.ajax({
                            url: '/members',
                            type: "GET",
                            async: false,
                            data: $.param( $('#payer_number').val() ? { number: $('#payer_number').val() } :
                                                                        { full_name: $('#payer_name').val() } )
                        }).done(function(data) {
                            if (data.length == 1 &&
                                (AppiUtils.isSameName($('#payer_name').val(), data[0]['full_name']) ||
                                    AppiUtils.isSameNumber($('#payer_number').val(), data[0]['number'])) ) {
                                if (!$('#payer_name').val())
                                    $('#payer_name').val(data[0]['full_name']);
                                if (!$('#payer_number').val())
                                    $('#payer_number').val(data[0]['number']);

                                AppiUtils.addHiddenElementToForm('edit_payment_form', 'payer_id', data[0]['id']);
                                found = true;
                            }
                        }).fail(function() {
                            bootbox.dialog({
                                    message: __("Erro a obter dados do pagador."),
                                    title:   __("Erro no acesso à BD"),
                                    icon:    "fa-exclamation text-danger",
                                    buttons: { OK: { } }
                            });
                        });
        }

        $.when(xhr_member, xhr_payer).always(function(xhr_member, xhr_payer) {

            $.ajax({
                type: "POST",
                url: action == "edit" ? "/payments/" + $('#id').val() : "/payments",
                data: $('#edit_payment_form').serialize(),
                success: function(data, textStatus, xhr) {
                            var result = jQuery.parseJSON(xhr.responseText);
                            AppiUtils.showAlert(result.error_msg, result.success);
                            dtTable.ajax.reload();
                            dtTable.row().invalidate().draw();
                            modal.find('.spinner').hide();
                            $('#editPaymentModal').modal('hide');
                            },
                error: function(xhr, err, status) {
                            var error = jQuery.parseJSON(xhr.responseText);

                            modal.find('.spinner').hide();
                            for (var k in error) {
                                if (error.hasOwnProperty(k) && Array.isArray(error[k])) {
                                    var form_group = $('#'+k).closest('.form-group');
                                    var block = form_group.find('.help-block').not('#'+k+'-error');
                                    form_group.addClass('has-error');
                                    block.addClass('with-errors');
                                    block.find(".list-unstyled").remove();
                                    var list = block.append('<ul class="list-unstyled"></ul>').find('ul');
                                    error[k].forEach(function(val) {
                                        list.append('<li>' + val + '</li>');
                                    });
                                }
                                else {
                                    if (k == 'modal_msg') {
                                        bootbox.dialog({
                                            message: error['modal_msg'],
                                            title: error['modal_title'],
                                            icon: "fa-exclamation text-danger",
                                            buttons: { OK: { } }
                                        });
                                    }
                                }
                            }
                        }
            });
        }).fail(function() {
            //alert("O associado com este número tem um nome diferente do indicado.");
            modal.find('.spinner').hide();
        });
        event.preventDefault();
    }

    pub.DeletePaymentFormSubmitHandler = function(event) {
        var modal = $(this).closest('.modal');
        var aData = dtTable.row('.selected').data();

        modal.find('.spinner').show();
        $.ajax({
            type: "POST",
            url: "/payments/" + $('#id').val(),
            data: $('#delete_payment_form').serialize(),
            success: function(data, textStatus, xhr) {
                        dtTable.ajax.reload();
                        dtTable.row().invalidate().draw();
                        $("#delete_btn").closest("form").attr("action", "/payments/" + 0);
                        $('#edit_btn, #delete_btn').toggleClass('disabled', true).disabled = true;
                        $('#deletePaymentModal').modal('hide');
                    },
            error: function(xhr, err, status) {
                        var error = jQuery.parseJSON(xhr.responseText);
                    },
            complete: function(xhr, status) {
                var result = jQuery.parseJSON(xhr.responseText);
                modal.find('.spinner').hide();

                if (result.hasOwnProperty('modal_msg')) {
                    bootbox.dialog({
                        message: result.modal_msg,
                        title: result.modal_title,
                        icon: "fa-exclamation text-danger",
                        buttons: { OK: { } }
                    });
                }
                else {
                    AppiUtils.showAlert(result.error_msg, result.success);
                }
            }
        });
        event.preventDefault();
    }

    pub.EditMemberFormSubmitHandler = function(event) {
        var modal = $(this).closest('.modal');
        var form = $(this).closest('form');
        var action = modal.data('action');
        var origin_id = $(form).data('origin');

        modal.find('.spinner').show();
        $.ajax({
            type: "POST",
            url: action == "edit" ? "/members/" + $('#id').val() : "/members",
            data: form.serialize(),
            success: function(data, textStatus, xhr) {
                        var result = jQuery.parseJSON(xhr.responseText);
                        AppiUtils.showAlert(result.error_msg, result.success);
                        $('#'+origin_id+'_number').val(result.member_number);
                        modal.find('.spinner').hide();
                        modal.modal('hide');
                        },
            error: function(xhr, err, status) {
                        var error = jQuery.parseJSON(xhr.responseText);

                        modal.find('.spinner').hide();
                        for (var k in error) {
                            if(error.hasOwnProperty(k) && Array.isArray(error[k])) {
                                var form_group = $('#'+k).closest('.form-group');
                                var block = form_group.find('.help-block').not('#'+k+'-error');
                                form_group.addClass('has-error');
                                block.addClass('with-errors');
                                block.find(".list-unstyled").remove();
                                var list = block.append('<ul class="list-unstyled"></ul>').find('ul');
                                error[k].forEach(function(val) {
                                    list.append('<li>' + val + '</li>');
                                });
                            }
                        }
                    }
        });
        event.preventDefault();
    }

    /**
     * Submit handler for the receipts' email form.
     *
     * @param event The click event.
     */
    pub.EmailFormSubmitHandler = function(event) {
        var modal = $(this).closest('.modal');
        var form = $(this).closest('form');
        var formData; // = new FormData(form[0]);
        var aData;
        var numberEmails = 0;

        event.preventDefault();

        if (typeof tinymce != "undefined") {
            $('#message').val(tinymce.get("message").getContent());
        }

        formData = new FormData(form[0]);

        if (ef_validator.form()) {
            // We'll use this to indicate that ids is an array of payments' ids
            formData.append('payment_ids', true);

            if (dtTable.row('.selected').data()) {
                // Use selected row only
                aData = dtTable.rows('.selected').data();
            }
            else {
                // Use all rows!
                aData = dtTable.rows( {search:'applied'} ).data();
            }

            // Iterate through all payments and append their ids to formData
            // In this case the form doesn't have payables, so we will use that
            // in the controller to know that when there are no
            for (var i = 0; i < aData.length; i++) {
                // Note that we can't validade emails here, we'll have to do it in the controller
                if (aData[i]['payment_amount']) {
                    formData.append('ids[]', aData[i]['id']);
                    numberEmails += 1;
                }
            }

            bootbox.dialog({
                        message: "" +
                                ((numberEmails > 1) ? __("Irão ser enviadas :nr mensagens.", {"nr": numberEmails}) : __('Irá ser enviada 1 mensagem.')) +
                                "<br><br>" +
                                __("Confirma o envio?") +
                                "",
                        title:   __("Confirma envio?"),
                        icon:    "fa-question-circle text-danger",
                        buttons: {
                            send: {
                                label: __("Enviar"),
                                className: "btn-primary",
                                callback: function() {
                                    modal.find('.spinner').show();
                                    // Invoke mailer using Ajax
                                    $.ajax({
                                        type: "POST",
                                        url: "/mailer",
                                        data: formData,
                                        processData: false,
                                        contentType: false,
                                        success: function(data, textStatus, xhr) {
                                                    modal.find('.spinner').hide();
                                                    modal.modal('hide');
                                                },
                                        error: function(xhr, err, status) {
                                                    modal.find('.spinner').hide();
                                                    modal.modal('hide');
                                                },
                                        complete: function(xhr, status) {
                                                    var result = jQuery.parseJSON(xhr.responseText);
                                                    if (result.hasOwnProperty('error_msg'))
                                                        AppiUtils.showAlert(result.error_msg, result.success);
                                                    else {
                                                        if (result.message.match(/CSRF/)) {
                                                            result.message = "Sessão expirada!";
                                                            if (confirm(__('A sessão expirou, pelo que a operação não pode ser realizada. Prosseguir para a página de identificação?'))) {
                                                                location.reload();
                                                            }
                                                        }
                                                        AppiUtils.showAlert(result.message, false);
                                                    }
                                                }
                                    });
                                }
                            },
                            cancel: {
                                label: __("Cancelar"),
                                className: "btn-default",
                                callback: function() {
                                    ;
                                }
                            }
                        }
                });
        }
    }

    pub.OnShowEditPaymentModal = function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var action = button.data('action'); // Extract info from data-* attributes
        var modal = $(this);

        modal.find('.spinner').hide();

        // Clean any existing validation messages ...
        pef_validator.resetForm();
        $('#member_create_non_member_btn').prop('disabled', true);
        $('#payer_create_non_member_btn').prop('disabled', true);

        if (action == "edit") {
            // We're editing so hide application form import
            $('.custom-file-import').hide();

            // Get row data
            var aData = dtTable.row('.selected').data();

            // Check if the is a row selected (assumes datatables is configured to have only one selected row)
            if (null != aData)
            {
                AppiUtils.SetModalTitle(modal, __('Editar Pagamento'));

                $('#id').val(aData['id']);
                $('#member_number').val(aData['member_number']);
                $('#member_name').val(aData['member_name']);

                $('#payer_number').val(aData['payer_number']);
                $('#payer_name').val(aData['payer_name']);

                if ($('#member_number').val() == $('#payer_number').val() ||
                    $('#member_name').val() == $('#payer_name').val() ) {
                    $('#same_for_receipt').prop('checked', true);
                    $('#receipt_data_values').hide();
                }
                else {
                    $('#same_for_receipt').prop('checked', false);
                    $('#receipt_data_values').show();
                }

                // Set Fee option by text, because that's what we have.
                if (! $('#payable_id option').filter(function () { return decodeHtmlEntities($(this).html()) == aData['description']; }).val() ) {
                    /*
                     * This means item is excluded from options because is not 'recent' enough.
                     * Get it from cache!
                     */
                    const itemObject = Object.fromEntries(Object.entries(payablesLocalCache).filter(([key, value]) => value['name'] === aData['description']))
                    if (itemObject) {
                        const valueArray = Object.keys(itemObject).map(key => itemObject[key]);

                        $("#payable_id").append('<option value=' + valueArray[0].id + '>' + valueArray[0].name + '</option>\n');
                    }
                }
                $('#payable_id').val( $('#payable_id option').filter(function () { return decodeHtmlEntities($(this).html()) == aData['description']; }).val() );

                // And then refresh
                $('#payable_id').selectpicker('refresh');

                //$('#payment_date').val(aData['payment_date']);
                $('#payment_date').datepicker('setDate', AppiUtils.toDate(aData['payment_date']));

                // For now, we have the text here, so we just use select val()
                $('#payment_method_id').val( $('#payment_method_id option').filter(function () { return $(this).html().toLowerCase() == aData['payment_method'].toLowerCase(); }).val() );
                $('#payment_method_id').selectpicker('refresh');

                $('#payment_amount').val(aData['payment_amount']);
                $('#payment_proof').val(aData['payment_proof']);

                if (aData['alt_desc']) {
                    $('#has_alt_desc').prop("checked", true);
                    $('#alt_desc').val(aData['alt_desc']);
                    $('#alt_desc').show();
                }
                else {
                    $('#has_alt_desc').prop("checked", false);
                    $('#alt_desc').hide();
                    $('#alt_desc').val('');
                }

                // Force validation on data we got from DB
                pef_validator.form();

                modal.data( "action", 'edit' );
            }
            else {
                // There was nothing selected (TODO - User should have some kind of feedback.)
                return event.preventDefault();
            }
        }
        else if (action == "create") {
            $('.custom-file-import').show();
            AppiUtils.SetModalTitle(modal, __('Novo Pagamento'));

            $('#id').val('');
            $('#member_number').val('');
            $('#member_name').val('');
            $('#same_for_receipt').prop( "checked", true);
            $('#receipt_data_values').hide();
            $('#payer_number').val('');
            $('#payer_name').val('');
            $('#payable_id').val(''); $('#payable_id').selectpicker('deselectAll');
            $('#payment_date').val('');
            $('#payment_date').datepicker('setDate', new Date());
            $('#payment_method_id').val(''); $('#payment_method_id').selectpicker('deselectAll');
            $('#payment_amount').val('');
            $('#payment_proof').val('');

            $('#has_alt_desc').prop("checked", false);
            $('#alt_desc').hide();
            $('#alt_desc').val('');

            // Reset validation errors
            pef_validator.resetForm();

            modal.data( "action", 'create' );
        }
    }

    pub.OnShowDeletePaymentModal = function (event) {
        var aData = dtTable.row('.selected').data();
        var modal = $(this);

        modal.find('.spinner').hide();

        // Reset all fields
        $(event.target).find('form')[0].reset();

        // Delete any existing text on modal
        $("form#delete_payment_form").closest('.modal-body').find('p').remove();
        $("form#delete_payment_form").closest('.modal-body').find('h4').remove();

        if (aData != null)
        {
            $('#id').val(aData['id']);
            $(this).find('.modal-body').prepend("<p><b>" + __('Data:') + "</b> <span id='delete_date'> </span></p>");
            $(this).find('.modal-body').prepend("<p><b>" + __('Valor:') + "</b> <span id='delete_amount'> </span></p>");
            $(this).find('.modal-body').prepend("<p><b>" + __('Pagador:') + "</b> <span id='delete_payer'> </span></p>");
            $(this).find('.modal-body').prepend("<p><b>" + __('Descrição:') + "</b> <span id='delete_name'> </span></p>");
            $(this).find('.modal-body').prepend("<h4><b>" + __('Confirma eliminação do Pagamento:') + "</b></h4>");
            $(this).find('#delete_name').text(aData['description'] ? aData['description'] : '-');
            $(this).find('#delete_payer').text(aData['member_name'] ? aData['member_name'] : '-');
            $(this).find('#delete_amount').text(aData['payment_amount'] ? aData['payment_amount'] : '-');
            $(this).find('#delete_date').text(aData['payment_date'] ? aData['payment_date'] : '-');
        }
    }

    pub.OnShowReceiptEmailModal = function (event) {
        $(this).find('.spinner').hide();

        // Reset form validator
        ef_validator.resetForm();

        // Reset all fields
        $(event.target).find('form')[0].reset();
        if (typeof tinymce != "undefined") {
            if (window.email_signature == undefined)
                tinymce.get("message").setContent('');
            else
                tinymce.get("message").setContent(window.email_signature);
        }
        else {
            $('#message').val(window.email_signature);
        }

        if (dtTable.rows('.selected').count() == 1) {
            // Use the selected row
            AppiUtils.SetModalTitle(this, __('Enviar email para quem fez o pagamento selecionado'));
        }
        else if (dtTable.rows('.selected').count() == 0) {
            AppiUtils.SetModalTitle(this, __('Enviar email para todos os que fizeram os pagamentos existentes na tabela'));
        }
        else {
            // Use all rows!
            AppiUtils.SetModalTitle(this, __('Enviar email para quem fez os pagamentos selecionados'));
        }
    }

    pub.OnShowMemberModal = function (event) {
        var button = event.relatedTarget;
        var modal = $(this);
        var form = modal.find('form');

        modal.find('.spinner').hide();

        // Remember if this came from member or payer
        $(form).data('origin', $(button).data('origin'));

        $(form)[0].reset();
        mf_validator.resetForm();

        AppiUtils.addHiddenElementToForm('edit_member_form', 'non_member', true);

        if (button) {
            if (form_data) {
                AppiUtils.ajaxCheckZipCode(form_data);
                $('#id').val('');
                $('#number').val('').closest('.form-group').hide();
                $('#full_name').val(form_data['member_name']);
                $('#last_name').val(form_data['member_name'].substring(form_data['member_name'].lastIndexOf(' ') + 1));
                $('#birth_date').datepicker('setDate', AppiUtils.toDate(form_data['birth_date']));
                $('#address').val(form_data['address']);
                $('#address').prop('title', 'Enviado: \n' + form_data['raw_address']);
                $('#zip_code').val(form_data['zip']);
                $('#place').val(form_data['place']);
                $('#district').val(form_data['district']);
                $('#country').val(form_data['country']);
                if (form_data['phone'].startsWith('2'))
                    $('#fixed_phone').val(form_data['phone']);
                else if (form_data['phone'].startsWith('9'))
                    $('#mobile_phone').val(form_data['phone']);
                else
                    $('#other_phone').val(form_data['phone']);
                $('#edu_institution').val(form_data['edu_institution']);
                $('#edu_level').val(form_data['edu_level']);
                $('#nif').val(form_data['nif']);
                $('#ncc').val('');
                $('input:radio[name=newsletter]').filter('[value=true]').prop('checked', true);
                $('#admission_year').val('');
                $('#email').val(form_data['email']);
                $('input:radio[name=active]').filter('[value=false]').prop('checked', true);
                $('#observations').val('');
            }
            else {
                $('#id').val('');
                $('#number').val('').closest('.form-group').hide();
                $('#full_name').val('');
                $('#last_name').val('');
                $('#birth_date').datepicker('setDate', null);
                $('#address').val('');
                $('#zip_code').val('');
                $('#place').val('');
                $('#country').val('Portugal');
                $('#district').val('');
                $('#fixed_phone').val('');
                $('#mobile_phone').val('');
                $('#other_phone').val('');
                $('#edu_institution').val('');
                $('#edu_level').val('');
                $('#nif').val('');
                $('#ncc').val('');
                $('input:radio[name=newsletter]').filter('[value=true]').prop('checked', true);
                $('input:radio[name=active]').filter('[value=true]').prop('checked', true);
                $('#admission_year').val(new Date().getFullYear()); //.closest('.form-group').hide();
                $('#email').val('');
                $('#observations').val('');
            }

            $('#member_create_non_member_btn').prop('disabled', true);
            $('#payer_create_non_member_btn').prop('disabled', true);

            // Prepare for submit
            modal.data( "action", 'create' );
        }
    }

    /**
     * Checks the validity of a name and/or number in the members DB table.
     * This handles wo buttons:
     *      Member verification: html id = *_member_*
     *      Payer verification:  html id = *_payer_*
     *
     * @param evt The click event.
     */
    pub.CheckMemberHandler = function(evt) {
        var btn_id = evt.target.id;
        var start = btn_id.indexOf("_") + 1;
        var stop = btn_id.lastIndexOf("_");
        var target_name = btn_id.substring(start, stop);
        var non_member_create_button_id = $(evt.target).closest('.form-group').next().find('button').attr('id');
        var name_str = target_name == 'member' ? __('Associado') : __('Pagador');

        if ($('#'+target_name+'_number').val() || $('#'+target_name+'_name').val()) {
            $.ajax({
                url: '/members',
                type: "GET",
                async: false,
                data: $.param( $('#'+target_name+'_number').val() ?
                                    { number: AppiUtils.formatMemberNumber($('#'+target_name+'_number').val()) } :
                                    { full_name: $('#'+target_name+'_name').val() } )
            }).done(function(data) {
                if (data.length == 1 &&
                    (AppiUtils.isSameName($('#'+target_name+'_name').val(), data[0]['full_name']) ||
                    AppiUtils.isSameNumber($('#'+target_name+'_number').val(), data[0]['number'])) ) {
                    if ($('#'+target_name+'_name').val() != data[0]['full_name']) {
                        AppiUtils.flash('#'+target_name+'_name');
                        $('#'+target_name+'_name').val(data[0]['full_name']);
                    }

                    if ($('#'+target_name+'_number').val() != data[0]['number']) {
                        AppiUtils.flash('#'+target_name+'_number');
                        $('#'+target_name+'_number').val(data[0]['number']);
                    }
                }
                else {
                    $('#'+non_member_create_button_id).prop('disabled', false);
                    if (data.length == 0) {
                        bootbox.dialog({
                            message: __("Não existem registos com este nome e/ou número"),
                            title: __("Verificação do :name", {"name": name_str}),
                            icon: "fa-exclamation text-danger",
                            buttons: { OK: { } }
                        });
                    }
                    else {
                        bootbox.dialog({
                            message: __('Existem :nr registos com este nome e/ou número', {"nr": data.length}),
                            title: __("Verificação do :name", {"name": name_str}),
                            icon:    "fa-exclamation text-danger",
                            buttons: { OK: { } }
                        });
                    }
                }
            }).fail(function() {
                bootbox.dialog({
                    message: __("Erro a obter dados do :name a partir da BD", {"name": name_str}),
                    title: __("Verificação do :name", {"name": name_str}),
                    icon: "fa-exclamation text-danger",
                    buttons: { OK: { } }
                });
            });
        }
    }

    /**
     * Associates the specified table id with a datatables table.
     *
     * @param {string} id - The table id.
     */
    pub.AttachDatatable = function(id) {
    	dtTableId = id;

        // Default opacity to 50% when nothing is loaded.
        $(dtTableId).css({ opacity: 0.5 });

        // Default dates for start and stop datepickers
        $('#start_date').datepicker('setDate', new Date(new Date().getFullYear()-3, 0, 1));
        $('#end_date').datepicker('setDate', new Date());

        // Define a DOM observer so that we may be able to clear validation errors
        var target = document.querySelector('#edit_payment_form');
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.attributeName === "class") {
                    var attributeValue = $(mutation.target).prop(mutation.attributeName);
                    if (attributeValue.indexOf('form-group') != -1) {
                        if (attributeValue.indexOf('has-error') == -1) {
                            $('.form-group').find(".list-unstyled").remove();
                        }
                    }
                }
            });
        });
        observer.observe(target, { attributes: true, subtree: true });

        // Init form validators
        pef_validator = $('#edit_payment_form').validate(AppiUtils.JQueryValidateOptions);
        ef_validator = $('#email_receipt_form').validate(AppiUtils.JQueryValidateOptions);
        mf_validator = AppiUtils.initFormValidation('edit_member_form');

        // Define callback to import EML files.
        $('#payment_form_file').change(this.ImportApplicationForm);

        // Get fees and add "active" ones to "available payments" select box.
        $.ajax( { url: "fees", async: false, type: 'GET' } )
            .done(function(data) {
                for (var i = 0; i < data.length; i++) {
                    payablesLocalCache[data[i].id.toString()] = data[i];
                    if (paymentIsActive(AppiUtils.toDate(data[i].due_date))) {
                        $("#payable_id").append('<option value=' + data[i].id + '>' + data[i].name + '</option>\n');
                    }
                }
                $('#payable_id').selectpicker('refresh');
            })
            .fail(function() {
                bootbox.dialog({
                        message: __("Erro a obter quotas definidas."),
                        title:   __("Erro no acesso à BD"),
                        icon:    "fa-exclamation text-danger",
                        buttons: { OK: { } }
                });
            }
        );

        // Get events and add "active" ones to "available payments" select box.
        $.ajax("events")
            .done(function(data) {
                for (var i = 0; i < data.length; i++) {
                    payablesLocalCache[data[i].id.toString()] = data[i];
                    if (paymentIsActive(AppiUtils.toDate(data[i].end_date))) {
                        $("#payable_id").append('<option value=' + data[i].id + '>' + data[i].name + '</option>\n');
                    }
                }
                $('#payable_id').selectpicker('refresh');
            })
            .fail(function() {
                bootbox.dialog({
                        message: __("Erro a obter eventos definidos."),
                        title:   __("Erro no acesso à BD"),
                        icon:    "fa-exclamation text-danger",
                        buttons: { OK: { } }
                });
            }
        );

        // Get payment methods and add them to "payment methods" select box.
        $.ajax( "payment_methods" )
            .done(function(data) {
                for (var i = 0; i < data.length; i++) {
                    $("#payment_method_id").append('<option value=' + data[i].id + '>' + data[i].name + '</option>\n');
                }
                $('#payment_method_id').selectpicker('refresh');
            })
            .fail(function() {
                bootbox.dialog({
                        message: __("Erro a obter métodos de pagamento definidos."),
                        title:   __("Erro no acesso à BD"),
                        icon:    "fa-exclamation text-danger",
                        buttons: { OK: { } }
                });
            }
        );

        // Define date format used in datatable to provide sort functionality
        $.fn.dataTable.moment('DD/MM/YYYY');

        /**
         * Fairly simply, this plug-in will take the data from an API result set
         * and sum it, returning the summed value. The data can come from any data
         * source, including column data, cells or rows.
         *
         * Note that it will attempt to 'deformat' any string based data that is passed
         * into it - i.e. it will strip any non-numeric characters in order to make a
         * best effort attempt to sum all data types. This can be useful when working
         * with formatting numbers such as currency. However the trade-off is that no
         * error is thrown if non-numeric data is passed in. You should be aware of this
         * in case unexpected values are returned - likely the input data is not what is
         * expected.
         *
         *  @name sum()
         *  @summary Sum the values in a data set.
         *  @author [Allan Jardine](http://sprymedia.co.uk)
         *  @requires DataTables 1.10+
         *
         *  @returns {Number} Summed value
         *
         *  @example
         *    // Simply get the sum of a column
         *    var table = $('#example').DataTable();
         *    table.column( 3 ).data().sum();
         *
         *  @example
         *    // Insert the sum of a column into the columns footer, for the visible
         *    // data on each draw
         *    $('#example').DataTable( {
         *      drawCallback: function () {
         *        var api = this.api();
         *        $( api.table().footer() ).html(
         *          api.column( 4, {page:'current'} ).data().sum()
         *        );
         *      }
         *    } );
         */
        jQuery.fn.dataTable.Api.register( 'sum()', function ( ) {
            return this.flatten().reduce( function ( a, b ) {
                if ( typeof a === 'string' ) {
                    a = a.replace(/[^\d.-]/g, '') * 1;
                }
                if ( typeof b === 'string' ) {
                    b = b.replace(/[^\d.-]/g, '') * 1;
                }

                return a + b;
            }, 0 );
        } );

        // Attach datatable to our table
        dtTable = $(dtTableId).on('preXhr.dt', function ( e, settings, data ) {
                            $(dtTableId).css({ opacity: 0.5 });
                            $('#spinner').show();
                        }).on( 'xhr.dt', function () {
                            $('#spinner').hide();
                            $(dtTableId).css({ opacity: 1 });
                        }).DataTable( {
                            //"pagingType": "input",
                            buttons: [
                                { extend: 'copy',  text: __('Copiar'), exportOptions: { columns: ':visible' } },
                                { extend: 'excel', text: __('Excel'), exportOptions: { columns: ':visible' } },
                                { extend: 'pdf',   text: __('Pdf'), exportOptions: { columns: ':visible' }, orientation: 'landscape' },
                                { extend: 'print', text: __('Imprimir'), exportOptions: { columns: ':visible' }, orientation: 'landscape' },
                            ],
                            select: {
                                style: 'os' // Was 'single'
                            },
                            "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "Tudo"]],
                            "autoWidth": true,
                            "order": [[ 2, "desc" ]],
                            //stateSave: true, // Can't be used with 'responsive'
                            responsive: true,  // Can't be used with 'stateSave'
                            "ajax": {
                                "url": "payments",
                                "data": function() {
                                    return {
                                        start_date: $('#start_date').val(),
                                        end_date: $('#end_date').val()
                                    };
                            },
                            "dataSrc": ""
                        },
                        "deferRender": true,
                        "initComplete": function(settings, json) {
                            // Add buttons to page.
                            dtTable.buttons().container().appendTo('#button_container');
                        },
                        "columns": [
                            { "title": "Id", "data": "id", "visible": false, "className": "none" },
                            { "title": __("Descrição"), "data": "description" },
                            { "title": __("Data"), "data": "payment_date" },
                            { "title": __("Valor"), "data": "payment_amount", render: AppiUtils.renderCurrencyColumn(), "className": "dt-body-right" },
                            { "title": __("Método de Pagamento"), "data": "payment_method" },
                            { "title": __("Prova de Pagamento"), "data": "payment_proof" },
                            { "title": __("Número do Beneficiário"), "data": "member_number" },
                            { "title": __("Nome do Beneficiário"), "data": "member_name" },
                            { "title": __("Número do Pagador"), "data": "payer_number", "className": "none" },
                            { "title": __("Nome do Pagador"), "data": "payer_name", "className": "none" },
                            { "title": __("NIF do Pagador"), "data": "payer_nif", "className": "none" },
                            { "title": __("Descrição no Recibo"), "data": "alt_desc", "visible": false, "className": "none" },
                        ],
                        "columnDefs": [
                            { "targets": [0], "visible": false, "searchable": false },
                            { "targets": [3], className: "dt-head-center" },
                            { "targets": [5], "render": function ( data, type, full, meta ) {
                                                            if (data)
                                                                return '<a href="'+data+'" onclick="javascript:window.open(\'' + data + '\', \'Popup\', \'location=1,status=1,scrollbars=1, resizable=1, directories=0, toolbar=0, titlebar=0, width=800, height=800\'); return false;">Download</a>';
                                                            else
                                                                return '';
                                                        }
                            },
                            { "targets": [6], "className": "dt-right", type: "unnatural" },
                            { "targets": [7], type: 'accented-string'},
                            { "targets": [8], "className": "dt-right", type: "unnatural" },
                            { "targets": [9], type: 'accented-string'},
                        ],
                        "footerCallback": function ( row, data, start, end, display ) {

                            if (!APPIconfig['datatables_sum_currency_columns']) {
                                return;
                            }

                            var api = this.api(), data;
                            var valueFormat = $.fn.dataTable.render.number( '.', ',', 2, '', ' €' ).display;

                            // Update footer
                            $(api.column(3).footer()).html(
                                __("Pág.: ") + valueFormat(api.column(3, {page:'current'} ).data().sum())
                            );
                            $(api.column(4).footer()).html(
                                __("Total: ") + valueFormat(api.column(3, {search:'applied'} ).data().sum())
                            );
                        },
                        "language": {
                            "url": __("//cdn.datatables.net/plug-ins/1.10.7/i18n/Portuguese.json"),
                            "decimal": ",",
                            "thousands": ".",
                            select: {
                                rows: {
                                    _: __("Selecionou %d linhas"),
                                    0: __("Clique numa linha para selecionar"),
                                    1: __("1 linha selecionada")
                                }
                            },
                            buttons: {
                                copySuccess: {
                                    1: __("1 linha copiada para o clipboard"),
                                    _: __("Copiadas %d linhas para o clipboard")
                                },
                                copyTitle: __('Copiar para o clipboard'),
                                copyKeys: 'Clique <i>ctrl</i> ou <i>\u2318</i> + <i>C</i> para copiar os dados da tabela para o clipboard. <br><br>Para anular, clique nesta mensagem ou Esc.'
                            }
                        }
        } );
        if (dtTable) {

            $('#has_alt_desc').change( function() {
                if ($(this).is(':checked')) {
                    $('#alt_desc').val('');
                    $('#alt_desc').show();
                }
                else {
                    $('#alt_desc').val('');
                    $('#alt_desc').hide();
                }
            });

            $("#refresh_btn").on('click', function(evt) {
                dtTable.ajax.reload();
            });

            // Deselect all if sorted, page changed, or table filtered
            dtTable.on('order.dt page.dt search.dt', function () {
                dtTable.rows().deselect();
                pageTotal = 0;
            } );

            // ///////////////////////////////////////////////////////////////////////////////////////
            // Table select/deselect handlers
            dtTable.on('select deselect', function(e, dt, type, indexes) {
                if (type === 'row')
                    if (dtTable.rows('.selected').count() == 1) {
                        var selected_payment_id = dtTable.rows(indexes).data().pluck('id')[0];
                        $("#delete_btn").closest("form").attr("action", "/payments/" + selected_payment_id);
                        $("#receipt_btn").attr("href", "/payments/" + selected_payment_id);
                        $('#edit_btn, #delete_btn, #receipt_btn').toggleClass('disabled', false).disabled = false;
                    }
                    else {
                        $("#delete_btn").closest("form").attr("action", "/payments/" + 0);
                        $("#receipt_btn").attr("href", "#");
                        $('#edit_btn, #delete_btn, #receipt_btn').toggleClass('disabled', true).disabled = true;
                    }
            } );
            // Table select/deselect handlers
            // ///////////////////////////////////////////////////////////////////////////////////////

            // Clean modal when it is hidden
            $("#editPaymentModal").on('hidden.bs.modal', function () {
                $('.form-group').removeClass('has-error has-feedback');
                $('.form-group').find(".list-unstyled").remove();
                $('.form-group').find('small.help-block').hide();
                $('.form-group').find('i.form-control-feedback').hide();
                form_data = null;
            });

            // Define click handlers to submit forms using AJAX.
            $("form#edit_payment_form button#submit").click(this.EditPaymentFormSubmitHandler);
            $("form#delete_payment_form button#submit").click(this.DeletePaymentFormSubmitHandler);
            $("form#edit_member_form button#submit").click(this.EditMemberFormSubmitHandler);
	        $("form#email_receipt_form button#submit").click(this.EmailFormSubmitHandler);

            // Click hadler to check member using AJAX
            $('#check_member_btn').on('click', this.CheckMemberHandler);
            $('#check_payer_btn').on('click', this.CheckMemberHandler);

            // Populate modals before they are shown.
            $('#editPaymentModal').on('show.bs.modal', this.OnShowEditPaymentModal);
            $('#deletePaymentModal').on('show.bs.modal', this.OnShowDeletePaymentModal);
            $('#emailReceiptModal').on('show.bs.modal', this.OnShowReceiptEmailModal);
            $('#editMemberModal').on('show.bs.modal', this.OnShowMemberModal);

            $('#emailReceiptModal').on('shown.bs.modal', function(event) {
                if (typeof tinymce != "undefined") {
                    tinyMCE.get("message").focus();
                    tinymce.get("message").selection.setCursorLocation(tinymce.get("message").getBody(), 0);
                    $("#dummy_input").focus(); // Fuck weird bootstrap/jquery/tinymce interaction!
                }
                $("#subject").focus();
            });

            // Filters
            AppiFilters.initDatatablesFilters(dtTable);
        }

        // When "payment proof" button is pressed we want to show it in a different window.
        $('#payment_proof_btn').on('click', function(evt) {
            if ($('#payment_proof').val()) {
                window.open($('#payment_proof').val(), 'Popup', 'location=1,status=1,scrollbars=1, resizable=1, directories=0, toolbar=0, titlebar=0, width=800, height=800');
            }
            evt.preventDefault();
        });

        // http://stackoverflow.com/questions/21018970/bootstrap-select-plugin-not-work-with-jquery-validation
        $('#edit_payment_form select').on('change', function(e) {
            $('#edit_payment_form').validate().element($(this));
        });

        // Update amount when payable is selected, if not already set.
        $('#payable_id').on('change', function(evt) {
            if (!$('#payment_amount').val() && payablesLocalCache[this.value])
                $('#payment_amount').val(payablesLocalCache[this.value].amount_due);
        });

        // Check if member data and receipt data are the same
        $('#receipt_data_question').on('change', function(evt) {
            if ($(this).find('input').is(":checked")) {
                $('#receipt_data_values').hide();
            }
            else {
                $('#receipt_data_values').show();
            }
        });

    }

    // Return the public part of this object
	return pub;
}());